<template>
  <div class="wax_guide">
    <Container>
      <Breadcrumbs :items="pageBreadcrumbs" />
    </Container>
    <component
      :is="componentName(element)"
      v-for="element in page.elements"
      :key="element.id"
      :element="element"
    />
  </div>
</template>

<script>
  import { AlchemyPage } from "~/mixins/alchemyVue"
  import AlchemyBreadcrumbs from "~/mixins/alchemybreadcrumbs"
  import { useNotificationStore } from "~/pinia/notifications"

  export default {
    components: {
      headline: defineAsyncComponent(
        () => import("~/alchemy/elements/headline"),
      ),
      image_full_width: defineAsyncComponent(
        () => import("~/alchemy/elements/image_full_width"),
      ),
      masthead: defineAsyncComponent(
        () => import("~/alchemy/elements/masthead"),
      ),
      text_block: defineAsyncComponent(
        () => import("~/alchemy/elements/text_block"),
      ),
      wax_guide_table: defineAsyncComponent(
        () => import("~/alchemy/elements/wax_guide_table"),
      ),
    },
    mixins: [AlchemyPage, AlchemyBreadcrumbs],
    setup(props) {
      useHead(usePageInfo(props.page))
      const notificationStore = useNotificationStore()
      onMounted(() => {
        notificationStore.announce(props.page.title)
      })
    },
  }
</script>
